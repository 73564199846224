import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTAButton from '../components/CTAButton/CTAButton';
import DecoAxonLines from '../components/DecoAxonLines/DecoAxonLines';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import StandardTextBlock from '../components/StandardTextBlock/StandardTextBlock';
import TricolorMorphDef from '../components/TricolorMorphDef/TricolorMorphDef';
import { PageComponent } from '../types/gatsby.types';
import { ColorPalette } from '../utils/colors.utils';
import './thank-you.scss';

type PageThankYouProps = {}

const PageThankYou: PageComponent = props => {
  
  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="PageThankYou"
      title="Thank You"
      backgroundColor={ColorPalette.ivory}
      foregroundColor={ColorPalette.dark}
    >
      <TricolorMorphDef />
      <div className="ContactFormSuccessScreen">
        <DecoAxonLines
          id="ContactFormSuccessScreenDecoAxonLines"
          // paths={{
          //   FG: 'M-81 145L136.744 198.704C140.186 199.553 143.341 201.301 145.884 203.77L235.755 290.997C240.86 295.953 248.214 297.823 255.066 295.908L373.357 262.856C377.661 261.653 382.244 261.927 386.375 263.633L468.333 297.485C470.754 298.485 473.348 299 475.968 299H549.184C550.392 299 551.598 299.109 552.786 299.327L615.429 310.797C619.694 311.578 624.097 310.951 627.975 309.012L681.338 282.331C683.103 281.449 684.989 280.832 686.934 280.502L737 272',
          //   R: 'M856 -406L800.448 -277.354C799.491 -275.136 798.139 -273.11 796.459 -271.375L778.304 -252.614C772.673 -246.796 771.106 -238.156 774.334 -230.731L798.197 -175.846C798.731 -174.618 799.387 -173.448 800.154 -172.351L852.288 -97.875C857.893 -89.8677 856.889 -78.9821 849.915 -72.1344L812 -34.9088C808.763 -31.731 806.703 -27.5472 806.157 -23.0442L799.349 33.1181C799.118 35.0294 798.611 36.8971 797.846 38.6636L751.349 145.964C748.639 152.218 749.3 159.421 753.103 165.076L777.574 201.469C782.56 208.885 782.022 218.709 776.255 225.535L737 272',
          //   G: 'M1367 423H1189.67C1188.56 423 1187.45 423.093 1186.35 423.277L1084.87 440.333C1075.6 441.89 1066.5 436.788 1063 428.07L1034.81 357.96C1031.88 350.678 1024.96 345.781 1017.12 345.44L921.481 341.282C917.268 341.099 913.106 342.252 909.587 344.576L870.736 370.246C863.695 374.898 854.493 374.63 847.735 369.577L755.293 300.462C753.12 298.838 751.301 296.788 749.945 294.439L737 272',
          //   B: 'M-150 886L-1.1476 830.18C0.936181 829.399 2.87494 828.275 4.58875 826.855L163.219 695.446C168.112 691.393 170.788 685.255 170.428 678.912L164.199 569.133C163.548 557.66 172.676 548 184.167 548H339.122C341.683 548 344.221 547.508 346.597 546.55L397.476 526.047C405.043 522.997 410 515.656 410 507.497V477.981C410 467.756 417.713 459.178 427.88 458.094L565.385 443.436C582.101 441.654 589.294 421.294 577.407 409.407L551.516 383.516C543.561 375.561 543.73 362.612 551.889 354.868L647.629 263.996C652.261 259.6 658.714 257.679 664.997 258.829L737 272',
          // }}
          paths={{
            FG: 'M-136 773L133.422 699.701C135.133 699.236 136.899 699 138.672 699H215.716C221.02 699 226.107 696.893 229.858 693.142L306.011 616.989C308.619 614.381 311.899 612.544 315.486 611.683L375.54 597.27C381.512 595.837 386.495 591.739 389.054 586.156L403.339 554.988C405.672 549.898 410.032 546.018 415.36 544.294L538.816 504.326C543.446 502.827 547.37 499.691 549.854 495.507L649.312 327.951C653.864 320.283 662.943 316.554 671.571 318.811L775.549 346.005C782.611 347.852 790.118 345.705 795.136 340.404L834.083 299.252C837.86 295.261 843.113 293 848.609 293H871.963C876.517 293 880.936 291.445 884.487 288.593L1064.25 144.21C1066.07 142.748 1068.13 141.616 1070.34 140.865L1332 52',
            R: 'M-112 829L136.394 659.461C138.77 657.839 141.468 656.749 144.303 656.265L249.558 638.295C257.667 636.911 264.101 630.703 265.774 622.649L291.433 499.164C293.475 489.341 302.478 482.551 312.484 483.287L608.139 505.053C615.97 505.63 623.413 501.571 627.17 494.676L767.143 237.764C773.241 226.57 788.115 223.862 797.768 232.187L1004.17 410.208C1012.41 417.316 1024.82 416.53 1032.1 408.44L1288 124',
            G: 'M-121 834L58.8792 695L125.301 628.867C127.843 626.336 131.019 624.535 134.497 623.653L280.481 586.639C284.675 585.575 288.412 583.179 291.13 579.812L324.327 538.673C326.786 535.625 328.297 531.922 328.671 528.023L340.49 404.812C341.287 396.505 347.157 389.567 355.217 387.405L508.196 346.362C513.789 344.862 518.456 341.011 520.993 335.805L585.461 203.493C588.081 198.116 592.97 194.194 598.787 192.802L721.753 163.382C722.816 163.128 723.857 162.787 724.865 162.362L879.299 97.2915C884.292 95.1877 888.218 91.1484 890.178 86.0975L1002 -202',
            B: 'M-82 820L48.6928 682.434C52.6977 678.218 58.3389 675.954 64.1469 676.232L202.471 682.84C204.806 682.951 207.142 682.653 209.373 681.958L306.432 651.725C308.79 650.99 311.264 650.699 313.728 650.866L422.861 658.239C431.331 658.811 439.24 653.973 442.588 646.171L512.11 484.151C515.603 476.01 524.036 471.137 532.833 472.175L750.768 497.889C754.198 498.294 757.673 497.804 760.857 496.466L961.918 412.017C963.302 411.436 964.746 411.012 966.224 410.754L1320 349',
          }}
          noStagger
        />
        <div className="ContactFormSuccessScreenInner">
          <StandardTextBlock animateTextEnter={{ delay: 100 }}>
            <h2>Thank you for getting in touch!</h2>
            <p>We will get back to you as soon as possible. You should also receive a confirmation in your inbox.</p>
          </StandardTextBlock>
          <CTAButton to="/" title="Go back to homepage" ribbons>Go to Homepage</CTAButton>
        </div>
      </div>
    </PageTemplate>
  )} />
  
}

export default PageThankYou;